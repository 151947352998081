import React from "react";
import Head from "next/head";
import { useAnalytics, useScript } from "../../hooks";
import appicon from "./appicon.json";
import Script from "next/script";
import { useState } from "react";
import { stripHTMLTags } from "../../util/Utils";
import { assetsBaseUrl } from "../../util/URLUtils";
import "default-passive-events";

/**
 * Note: Any elements within the <Head /> must be a basic react component or Next sees them as client side 
 * for some reason and they will not render on page load.
 */
const SiteHead = ({ content, channel, requestUrl }) => {
  const [is51DegLoaded, setIs51DegLoaded] = useState(false);
  const [isJqueryLoaded, setIsJqueryLoaded] = useState(false);

  useScript({
    type: "application/ld+json",
    textContent: JSON.stringify(appicon),
  });

  useAnalytics();

  const title = content ? content.name : channel ? channel.name : "PixelMixer";
  const posterUrl = content
    ? content.posterUrl
    : channel
    ? channel.splashUrl
    : `${assetsBaseUrl}/www/assets/img/play-blade-only-color.svg`;
  const description = stripHTMLTags(
    content && !content.requireAiReview
      ? content.description
      : channel
      ? channel.description
      : "Knowledge Community"
  );
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="content-type" content="text/html; charSet=utf-8" />
        <meta name="author" content="PixelMixer" />
        <title>PixelMixer Video</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={posterUrl} />
        <meta
          property="og:url"
          content={`${requestUrl ? requestUrl : "https://www.pixelmixer.com/"}`}
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link
          rel="shortcut icon"
          href={`${assetsBaseUrl}/www/assets/img/play-blade-only-color.svg`}
          type="image/x-icon"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/favicon/safari-pinned-tab.svg"
          color="#793de6"
        />
        <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content="/static/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          httpEquiv="Delegate-CH"
          content="sec-ch-ua-full-version-list https://cloud.51degrees.com; sec-ch-ua-model https://cloud.51degrees.com; sec-ch-ua-platform https://cloud.51degrees.com; sec-ch-ua-platform-version https://cloud.51degrees.com"
        />
      </Head>

      <Script
        onLoad={() => setIs51DegLoaded(true)}
        src="/static/plugins/51degrees/AQTPkKrCdAyFj3o23Eg.js"
      ></Script>
      {is51DegLoaded && <Script src="/static/js/deviceapi.js"></Script>}
      <Script src="https://www.googletagmanager.com/gtag/js?id=AW-731004586"></Script>
      {/* <Script src="https://unpkg.com/default-passive-events"></Script> */}
      <Script
        onLoad={() => setIsJqueryLoaded(true)}
        src="/static/plugins/jquery/jquery.min.js"
      ></Script>
    </>
  );
};

export default SiteHead;
